import React, { useState, useEffect } from "react";
import "./Navigation.css";
import "../../App.css";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { apiEndPoints } from "../../constants/apiEndPoints";

const initialProfileData = {
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	is_staff: "",
	date_joined: "",
};

function Navigation({ heading, loggedIn, onLogout, fetchUserData }) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [profileData, setProfileData] = useState(initialProfileData);
	const navigate = useNavigate();

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const token = localStorage.getItem("token");

				const response = await fetch(apiEndPoints.userProfile, {
					headers: {
						Authorization: `Token ${token}`,
					},
				});
				const userData = await response.json();
				setProfileData(userData);
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};

		fetchUserData();
	}, []);

	function menuIconClicked() {
		const sidebar = document.getElementById("sidebar");
		const content = document.getElementById("content");
		if (sidebar && content) {
			sidebar.classList.toggle("active");
			content.classList.toggle("content_active");
		} else {
			console.error("Sidebar or content element not found!");
		}
	}

	const handleLogout = () => {
		localStorage.removeItem("token");
		onLogout();
		navigate("/login");
	};

	return (
		<>
			<div className="iq-top-navbar fixed-header">
				<div className="iq-navbar-custom d-flex justify-content-between">
					<div className="iq-navbar">
						<div className="top-logo d-flex mr-4">
							<div className="menu-icon" onClick={menuIconClicked}>
								<FontAwesomeIcon icon={faBars} />
							</div>

							<span className="heading-das">{heading}</span>
						</div>
					</div>

					<nav className=" user-info navbar navbar-expand-lg navbar-light p-0 mx-4">
						<div
							className={`caption ${isDropdownOpen ? "active" : ""}`}
							onClick={toggleDropdown}>
							<FontAwesomeIcon icon={faUser} className="mx-2 user-icon" />
							{profileData.first_name}&nbsp;
							{profileData.last_name}
						</div>
						{isDropdownOpen && (
							<div className="iq-sub-dropdown iq-user-dropdown">
								<div class="bg-primary p-3">
									<h5 class="mb-0 text-white line-height">
										Hello {profileData.first_name}
									</h5>
								</div>
								<div className="iq-card shadow-none m-0">
									<div className="iq-card-body p-0">
										<div href="" className="iq-sub-card iq-bg-primary-hover">
											<div className="media text-center my-2">
												<div className="rounded-circle iq-card-icon iq-bg-primary">
													<FontAwesomeIcon
														icon={faUser}
														className="mx-2 user-icon"
													/>
												</div>
												<div className="mx-4">
													<h6 className="mb-0">My Profile</h6>
												</div>
											</div>
											<div className="media-body ms-3">
												{/* <span>User Details</span> */}
												<div className="row">
													<div className="profile_data">
														<div className="d-flex details">
															<div className="col-md-5">
																<label>First Name:</label>
															</div>
															<div className="col-md-7">
																{profileData.first_name}
															</div>
														</div>
														<div className="d-flex details">
															<div className="col-md-5">
																<label>Last Name:</label>
															</div>
															<div className="col-md-7">
																{profileData.last_name}
															</div>
														</div>
														<div className="d-flex details">
															<div className="col-md-5">
																<label>Email:</label>
															</div>
															<div className="col-md-7">
																{profileData.email}
															</div>
														</div>
														<div className="d-flex details">
															<div className="col-md-5">
																<label>Contact:</label>
															</div>
															<div className="col-md-7">
																{profileData.phone_number}
															</div>
														</div>
														<div className="d-flex ">
															<div className="col-md-5">
																<label>Joining Date:</label>
															</div>
															<div className="col-md-7">
																{profileData.date_joined.slice(0, 10)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="d-inline-block w-100 text-center p-3">
											<button
												className="bg-primary iq-sign-btn"
												onClick={handleLogout}>
												Sign out<i className="ri-login-box-line ms-2"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					</nav>
				</div>
			</div>
		</>
	);
}

export default Navigation;
