import "./DashboardAll.css";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Sidebar from "../Sidebar/Sidebar";
import Navigation from "../Navigation/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileInvoice,
	faBed,
	faMoneyBill,
	faHospital,
	faMoneyBillWave,
	faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import "../../App.css";
import { apiEndPoints } from "../../constants/apiEndPoints";

const initialCardData = {
	uploaded_invoices: "",
	num_organizations: "",
	num_doctors: "",
	num_patients: "",
	total_amount_paid: "",
	total_balance_due: "",
	total_number_converted_invoices: "",
};

function Dashboard() {
	const [cardData, setCardData] = useState(initialCardData);
	const [barChartData, setBarChartData] = useState({});
	const [pieChartData, setPieChartData] = useState({});
	const [showBarChart, setShowBarChart] = useState(true);
	const [showPieChart, setShowPieChart] = useState(true);

	useEffect(() => {
		fetchDataFromApi();
	}, []);

	const fetchDataFromApi = () => {
		const token = localStorage.getItem("token");

		axios
			.get(apiEndPoints.dashboard_chartAPI, {
				headers: {
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				const data = response.data;
				setCardData(data);

				const isBarDataZero = Object.values(
					data.invoice_summary["Total Invoices"] &&
						data.invoice_summary["Total Amount Paid"] &&
						data.invoice_summary["Total Due"]
				).every((value) => value === 0);

				if (!isBarDataZero) {
					setBarChartData({
						options: {
							xaxis: {
								categories: data.invoice_summary.months,
							},
						},
						series: [
							{
								name: "Total Invoices",
								data: data.invoice_summary["Total Invoices"],
							},
							{
								name: "Total Amount Paid",
								data: data.invoice_summary["Total Amount Paid"],
							},
							{
								name: "Total Due",
								data: data.invoice_summary["Total Due"],
							},
						],
					});
				}
				const isPieDataZero = Object.values(data.pie_chart_data).every(
					(value) => value === 0
				);

				if (!isPieDataZero) {
					setPieChartData({
						series: [
							data.pie_chart_data.uploaded_invoices,
							data.pie_chart_data.num_doctors,
							data.pie_chart_data.num_patients,
							data.pie_chart_data.num_organizations,
						],
						options: {
							labels: [
								"Uploaded Invoices",
								"Doctors",
								"Patients",
								"Organizations",
							],
						},
					});
				}
				setShowBarChart(!isBarDataZero);
				setShowPieChart(!isPieDataZero);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	return (
		<>
			<div className="working__area close__sidebar ">
				{showBarChart && barChartData.series ? (
					<div className="chart-sec">
						<div className="row">
							<div className="col-md-12">
								<div id="chart">
									<h4>Invoice summary</h4>
									<ReactApexChart
										options={barChartData.options}
										series={barChartData.series}
										type="bar"
										height={350}
									/>
								</div>
								<div id="html-dist"></div>
							</div>
						</div>
					</div>
				) : (
					<div className="chart-sec">
						<h4>Invoice summary</h4>
						<div className="chart_bar text-center mt-5">
							<img src="Images/bar_img.png" className="h-75" alt="chart" />
							<h1>No Data Found</h1>
						</div>
					</div>
				)}

				<div className="card-sec">
					<div className="col-lg-12">
						<div className="row">
							<div className="col-md-6 col-lg-4">
								<div className="iq-card">
									<div className="iq-card-body iq-bg-primary rounded-4">
										<div className="d-flex align-items-center justify-content-between">
											<div className="rounded-circle iq-card-icon iq-primary">
												<FontAwesomeIcon icon={faFileInvoice} />
											</div>
											<div className="text-end">
												<h3 className="mb-0">
													<span className="counter">
														{cardData.uploaded_invoices}
													</span>
												</h3>
												<h5 className="">Total Invoices</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="iq-card">
									<div className="iq-card-body iq-bg-warning rounded-4">
										<div className="d-flex align-items-center justify-content-between">
											<div className="rounded-circle iq-card-icon iq-warning">
												<FontAwesomeIcon icon={faMoneyBill} />{" "}
											</div>
											<div className="text-end">
												<h3 className="mb-0">
													<span className="counter">
														{cardData.total_amount_paid}
													</span>
												</h3>
												<h5 className="">Total Amount Paid</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 col-lg-4">
								<div className="iq-card">
									<div className="iq-card-body  iq-bg-danger rounded-4">
										<div className="d-flex align-items-center justify-content-between">
											<div className="rounded-circle iq-card-icon iq-danger">
												<FontAwesomeIcon icon={faMoneyBillWave} />
											</div>
											<div className="text-end">
												<h3 className="mb-0">
													<span className="counter">
														{cardData.total_balance_due}
													</span>
												</h3>
												<h5 className="">Total Due</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="row">
									<div className="col-md-6 col-lg-4">
										<div className="iq-card">
											<div className="iq-card-body iq-bg-info rounded-4">
												<div className="d-flex align-items-center justify-content-between">
													<div className="rounded-circle iq-card-icon iq-info">
														<FontAwesomeIcon icon={faBed} />{" "}
													</div>
													<div className="text-end">
														<h3 className="mb-0">
															<span className="counter">
																{cardData.num_patients}
															</span>
														</h3>
														<h5 className="">Count of patient</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-lg-4">
										<div className="iq-card">
											<div className="iq-card-body iq-bg-success rounded-4">
												<div className="d-flex align-items-center justify-content-between">
													<div className="rounded-circle iq-card-icon iq-success">
														<FontAwesomeIcon icon={faHospital} />
													</div>
													<div className="text-end">
														<h3 className="mb-0">
															<span className="counter">
																{cardData.num_organizations}
															</span>
														</h3>
														<h5 className="">Number of hospitals</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-lg-4">
										<div className="iq-card">
											<div className="iq-card-body iq-bg-secondary rounded-4">
												<div className="d-flex align-items-center justify-content-between">
													<div className="rounded-circle iq-card-icon iq-secondary">
														<FontAwesomeIcon icon={faUserDoctor} />
													</div>
													<div className="text-end">
														<h3 className="mb-0">
															<span className="counter">
																{cardData.num_doctors}
															</span>
														</h3>
														<h5 className="">Number of Doctors</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{showPieChart && pieChartData.series ? (
					<div className="chart-sec">
						<div id="pie-chart">
							<h4>Invoice summary</h4>
							<hr />
							<ReactApexChart
								options={pieChartData.options}
								series={pieChartData.series}
								type="pie"
								height={350}
							/>
						</div>
					</div>
				) : (
					<div className="chart-sec ">
						<h4>Invoice summary</h4>
						<div className="chart_image text-center mt-5">
							<img
								src="Images/pie-chart-xxl.png"
								className="h-75"
								alt="chart"
							/>
							<h1>No Data Found</h1>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default Dashboard;
