import React, { useState, useEffect } from "react";
import "./Login.css";
import { NavLink } from "react-router-dom";
import InputField from "../Component/InputField";
import { apiEndPoints } from "../constants/apiEndPoints";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Input, Button, message, Checkbox, Spin } from "antd";
import Cookies from "js-cookie";

const initialUserData = {
	email: "",
	password: "",
};
export default function Login({ setLoggedIn }) {
	const [userData, setUserData] = useState(initialUserData);
	const [rememberUser, setRememberUser] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const savedEmail = Cookies.get("email");
		const savedPassword = Cookies.get("password");

		if (savedEmail && savedPassword) {
			setUserData({
				email: savedEmail,
				password: savedPassword,
			});
			setRememberUser(true);
		}
	}, []);
	const navigate = useNavigate();
	const handleChange = (event) => {
		const { id, value } = event.target;
		setUserData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};
	const handleLoginSuccess = (token) => {
		localStorage.setItem("token", token);
		sessionStorage.setItem("loggedIn", "true");
		setLoggedIn(true);
		navigate("/dashboard");
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const response = await axios.post(apiEndPoints.userLogin, {
				email: values.email,
				password: values.password,
				remember_me: rememberUser,
			});
			if (response.data.message) {
				message.info(response.data.message);
				handleLoginSuccess(response.data.token);

				if (rememberUser) {
					Cookies.set("email", values.email, { expires: 7 });
					Cookies.set("password", values.password, { expires: 7 });
				} else {
					Cookies.remove("email");
					Cookies.remove("password");
				}
			}
			console.log(response);
		} catch (error) {
			console.error("Error occurred:", error);
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				message.error(error.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<section className="login-in-page">
				<div className="login-in-page-bg ">
					<div className="row d-flex">
						<div className="col-md-6 text-center login-img-sec">
							<div className="login-in-detail text-white">
								<div className="top">
									<h1>
										Medi<span>Scan</span>
									</h1>
								</div>
								<div className="img">
									<img
										src="Images/img3.png"
										className="img-fluid mb-4"
										alt="logo"
									/>
								</div>
								<div>
									<p>
										Efficiency in Every Scan, Precision in Every Bill: Mediscan
										Manages Your Invoices with Care.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 position-relative">
							<div className="login-in-from">
								<h1 className="mb-0">Sign in</h1>

								<Form className="mt-4" onFinish={handleSubmit}>
									<Form.Item
										name="email"
										label="Email Address"
										rules={[
											{ required: true, message: "Please input your email!" },
											{
												type: "email",
												message: "The input is not a valid email address!",
											},
										]}>
										<InputField type="email" placeholder="Enter email" />
									</Form.Item>
									<Form.Item
										name="password"
										label="Password"
										rules={[
											{
												required: true,
												message: "Please input your password!",
											},
										]}>
										<Input.Password placeholder="Enter Password" />
									</Form.Item>
									<div className="d-flex justify-content-between">
										<Form.Item name="remember" valuePropName="checked">
											<Checkbox
												onChange={(e) => setRememberUser(e.target.checked)}>
												Remember me
											</Checkbox>
										</Form.Item>
										<Form.Item>
											<div className="forgot_sec ">
												<div className="float-end">
													<NavLink to="/ForgotPas">Forgot password?</NavLink>
												</div>
											</div>
										</Form.Item>
									</div>
									<Form.Item className="button-login">
										<Button
											type="primary"
											htmlType="submit"
											className="float-none ">
											{loading ? <Spin /> : "Log In"}
										</Button>
									</Form.Item>

									<div className="login-info mt-4">
										<span className="dark-color d-inline-block line-height-2 mt-2">
											Don't have an account?
											<NavLink to="/SignUp" className="mx-2">
												Sign-up
											</NavLink>
										</span>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
